import clsx from 'clsx';
import React from 'react';
import { mergeProps, useButton } from 'react-aria';
import { useToggleState } from 'react-stately';

import { ContentText } from './ContentText';
import { IconCaretDown } from './icons/CaretDown';
import { IconCaretRight } from './icons/CaretRight';
import { useInteraction } from './useInteraction';

export interface CollapsibleDisclosureProps {
  children: React.ReactNode;
  label: string;
  size?: 'small' | 'large';
  expanded?: boolean;
  defaultExpanded?: boolean;
  onToggle?(expanded: boolean): void;
  icon?: React.ReactNode;
}

export const CollapsibleDisclosure = (props: CollapsibleDisclosureProps) => {
  const state = useToggleState({
    defaultSelected: props.defaultExpanded,
    isSelected: props.expanded,
    onChange: props.onToggle,
  });
  const ref = React.useRef(null);
  const { focusProps, isFocusVisible } = useInteraction({
    disabled: false,
  });

  const { buttonProps } = useButton(
    {
      ...props,
      onPress: state.toggle,
    },
    ref
  );

  return (
    <div className="hlx-collapsible-disclosure">
      <button
        ref={ref}
        className={clsx('hlx-collapsible-disclosure-title', {
          'focus-ring': isFocusVisible,
        })}
        aria-expanded={state.isSelected ? true : false}
        {...mergeProps(focusProps, buttonProps)}
      >
        {props.icon && (
          <span className="hlx-collapsible-disclosure-icon" aria-hidden>
            {props.icon}
          </span>
        )}
        <span className="hlx-collapsible-disclosure-label">
          <ContentText variant={props.size === 'large' ? 'body' : 'body-small'}>
            <strong>{props.label}</strong>
          </ContentText>
        </span>
        {state.isSelected ? (
          <IconCaretDown size={16} />
        ) : (
          <IconCaretRight size={16} />
        )}
      </button>
      {state.isSelected && <div>{props.children}</div>}
    </div>
  );
};
