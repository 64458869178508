export const InsuranceCardBack = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 134 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_497_13171)">
        <rect
          x="0.680908"
          y="0.000976562"
          width="132.632"
          height="80"
          rx="2.05928"
          fill="#E8F3FF"
        />
        <mask
          id="mask0_497_13171"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="134"
          height="81"
        >
          <rect
            x="0.680908"
            y="0.000976562"
            width="132.632"
            height="80"
            rx="2.05928"
            fill="#FAF3F3"
          />
        </mask>
        <g mask="url(#mask0_497_13171)">
          <rect
            x="9.43237"
            y="7.73877"
            width="16.2159"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
          <rect
            x="30.5134"
            y="7.73877"
            width="90.8091"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
          <rect
            x="30.5134"
            y="14.2251"
            width="66.4852"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
          <rect
            x="30.5134"
            y="20.7119"
            width="90.8091"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
          <rect
            x="9.43237"
            y="33.6836"
            width="110.268"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
          <rect
            x="9.43237"
            y="40.1699"
            width="66.4852"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
          <rect
            x="9.43237"
            y="51.5215"
            width="90.8091"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
          <rect
            x="9.43237"
            y="59.6294"
            width="90.8091"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
          <rect
            x="9.43237"
            y="67.7378"
            width="30.8102"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
          <rect
            x="105.103"
            y="51.5215"
            width="14.5943"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
          <rect
            x="105.103"
            y="59.6294"
            width="14.5943"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
          <rect
            x="105.103"
            y="67.7378"
            width="14.5943"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
          <rect
            x="9.43237"
            y="15.8472"
            width="16.2159"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
          <rect
            x="9.43237"
            y="25.5767"
            width="16.2159"
            height="3.24318"
            rx="0.686427"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect
            x="0.684082"
            width="132.632"
            height="80"
            rx="6.63158"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InsuranceCardBack;
