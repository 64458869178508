import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { ContentText } from '@headway/helix/ContentText';
import { IconDotsThree } from '@headway/helix/icons/DotsThree';
import { ProgressBar } from '@headway/helix/Progress';
import { InsuranceCardFront } from '@headway/shared/components/images/InsuranceCardFront';

const jumpingDotsAnimation = `
  @keyframes jump {
    0%, 20% { transform: translateY(0); }
    50% { transform: translateY(-12px); }
    75%, 100% { transform: translateY(0); }
  }
  .dot-1,
  .dot-2,
  .dot-3 {
    animation: jump .9s ease-in-out infinite;
  }

  .dot-1 {
    animation-delay: 0s;
  }

  .dot-2 {
    animation-delay: 0.2s;
  }

  .dot-3 {
    animation-delay: .4s;
  }
`;

const styles: { [key: string]: React.CSSProperties } = {
  checkingImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop: '30%',
  },
  scanningText: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'center',
    width: '100%',
    top: '55%',
    rowGap: '30px',
  },
  desktopOuterContainer: {
    paddingTop: '60px',
    paddingBottom: '80px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  mobileOuterContainer: {
    // purposely empty
  },
};

export const checkingImageReadabilityContent = (
  <div style={styles.checkingImageContainer}>
    <IconDotsThree size={48} />
    <ContentText variant="body">Checking your image..</ContentText>
    <style>{jumpingDotsAnimation}</style>
  </div>
);

interface ScanningCardScreenProps {
  isMobile: boolean;
}

const ScanningCardScreen: React.FC<ScanningCardScreenProps> = ({
  isMobile,
}: ScanningCardScreenProps) => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const updateValue = () => {
      // increment progress such that we get to 100% after 30 seconds.
      // setInterval uses milliseconds,
      // so we'll increment an accumulator by 1/300 every tenth of a second.
      const increment = 1 / 300;
      const interval = 100;
      let accumulation = 0;

      const intervalId = setInterval(() => {
        accumulation = accumulation + increment;
        setProgress(accumulation);
        if (accumulation >= 1) {
          clearInterval(intervalId);
        }
      }, interval);
    };
    updateValue();
  }, []);

  const progressIsComplete = progress >= 1;

  return (
    <div
      style={
        isMobile ? styles.mobileOuterContainer : styles.desktopOuterContainer
      }
    >
      <div className="flex h-full flex-col items-center justify-center">
        <div className="relative flex h-[100px] w-full flex-col items-center justify-center">
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: ['0px', '100px', '0px'] }}
            transition={{ duration: 2, repeat: Infinity, ease: 'easeInOut' }}
            className="bg-primary-brandGreen shadow-medium shadow-primary-brandGreen absolute top-0 h-[2px] w-1/2 shadow-sm"
          />
          <InsuranceCardFront height={100} />
        </div>
        <div className="mb-2 mt-8 w-4/5">
          <ProgressBar
            value={progress * 100}
            valueLabel={' '}
            aria-label="Progress"
          />
        </div>
      </div>
      <div style={styles.scanningText}>
        <ContentText variant="body/medium">Scanning your card...</ContentText>
        <div style={{ whiteSpace: 'pre-line' }}>
          <ContentText variant="body-small">
            {progressIsComplete ? (
              <>
                Almost there. {'\n'} We're just finishing up gathering your
                insurance details.
              </>
            ) : (
              <>
                This should only take a moment. {'\n'} Please keep this window
                open.
              </>
            )}
          </ContentText>
        </div>
      </div>
    </div>
  );
};
export default ScanningCardScreen;
