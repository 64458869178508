import { ContentText } from '@headway/helix/ContentText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { SelectorError } from '@headway/shared/types/insuranceCapture';

const SelectorErrorCard = ({
  selectorError,
}: {
  selectorError: SelectorError;
}) => {
  return (
    <GuidanceCard variant="warning" layout="vertical">
      {
        <>
          {selectorError === SelectorError.CAMERA_PERMISSIONS_DENIED_ERROR && (
            <>
              <ContentText>
                <strong>Camera access denied</strong>
              </ContentText>
              <ContentText>
                After clicking "Start Camera", please select "Allow" in the
                pop-up. If that doesn't work, update your system privacy
                settings to grant your browser access to the camera.
              </ContentText>
            </>
          )}
          {selectorError === SelectorError.CAMERA_GENERIC_ERROR && (
            <>
              <ContentText>
                <strong>We can't access your camera</strong>
              </ContentText>
              <ContentText>
                Select one of the other options or allow access to the camera
              </ContentText>
            </>
          )}
          {selectorError === SelectorError.UNPARSEABLE_IMAGE && (
            <>
              <ContentText>
                <strong>Oops, we can't read your images.</strong>
              </ContentText>
              <ContentText>
                Make sure you're taking photos of your insurance card. You can
                also upload images of your card if you have them or enter your
                insurance details manually.
              </ContentText>
            </>
          )}
          {selectorError === SelectorError.UPLOAD_FILE_TOO_LARGE && (
            <>
              <ContentText>
                <strong>Images are too large.</strong>
              </ContentText>
              <ContentText>
                We can only read files up to 10MB. Please upload smaller files,
                take new photos, or enter your insurance details manually.
              </ContentText>
            </>
          )}
          {selectorError === SelectorError.CATCHALL_ERROR && (
            <>
              <ContentText>
                <strong>Oops, there is something wrong.</strong>
              </ContentText>
              <ContentText>
                Please try again or enter your insurance details manually.
              </ContentText>
            </>
          )}
          {selectorError === SelectorError.PHOTO_UPLOAD_CATCHALL_ERROR && (
            <>
              <ContentText variant="body/medium">
                Oops, there is something wrong
              </ContentText>
              <ContentText>Please try again or skip for now.</ContentText>
            </>
          )}
        </>
      }
    </GuidanceCard>
  );
};

export default SelectorErrorCard;
