import axiosModule, { AxiosRequestConfig, Method } from 'axios';

import { InterpretInsuranceCardResponse } from '../models/InterpretInsuranceCardResponse';
import { ValidateAndUploadInsuranceCardToS3Response } from '../models/ValidateAndUploadInsuranceCardToS3Response';
import { ValidateInsuranceCardResponse } from '../models/ValidateInsuranceCardResponse';

export const axios = axiosModule.create({
  baseURL: process.env.REACT_APP_API_URL || process.env.API_URL,
  withCredentials: true,
  paramsSerializer: {
    indexes: null, // format array query params as `?a=1&a=2`
  },
});

export default axios;

// TODO(sc-75213): remove this in favor of codegened API when codegen logic is updated to handle file upload
export class HardcodedInsuranceCardInterpretationApiResource {
  /**
     * Validate Insurance Card Image
     * 
     * @param ownerId 
     * @param image 
     
     */
  public validateInsuranceCardImage(
    image: any,
    query: { owner_id: number },
    axiosConfig?: AxiosRequestConfig
  ): Promise<ValidateInsuranceCardResponse> {
    const apiRoute = '/insurance-card-interpretation/validate';
    const reqPath = apiRoute;
    const formData = new FormData();
    formData.append('image', image);
    let reqConfig = {
      ...axiosConfig,
      method: 'POST' as Method,
      url: reqPath,
      params: query,
      headers: { 'Content-Type': 'multipart/form' },
      data: formData,
    };
    return axios.request(reqConfig).then((res) => {
      return res.data;
    });
  }

  /**
     * Extract Insurance Card Info
     * 
     * @param ownerId 
     * @param backOfCard 
     * @param frontOfCard 
     
     */
  public extractInsuranceCardInfo(
    backOfCard: any,
    frontOfCard: any,
    query: { owner_id: number },
    axiosConfig?: AxiosRequestConfig
  ): Promise<InterpretInsuranceCardResponse> {
    const apiRoute = '/insurance-card-interpretation/extract-info';
    const reqPath = apiRoute;
    const formData = new FormData();
    formData.append('back_of_card', backOfCard);
    formData.append('front_of_card', frontOfCard);
    let reqConfig = {
      ...axiosConfig,
      method: 'POST' as Method,
      url: reqPath,
      params: query,
      headers: { 'Content-Type': 'multipart/form' },
      data: formData,
    };
    return axios.request(reqConfig).then((res) => {
      return res.data;
    });
  }

  /**
     * Validate And Upload Insurance Card To S3
     * 
     * @param ownerId 
     * @param backOfCard 
     * @param frontOfCard 
     
     */
  public validateAndUploadInsuranceCardToS3(
    backOfCard: any,
    frontOfCard: any,
    query?: { owner_id: number },
    axiosConfig?: AxiosRequestConfig
  ): Promise<ValidateAndUploadInsuranceCardToS3Response> {
    const apiRoute = '/insurance-card-interpretation/validate-and-upload-to-s3';
    const reqPath = apiRoute;
    const formData = new FormData();
    formData.append('back_of_card', backOfCard);
    formData.append('front_of_card', frontOfCard);
    let reqConfig = {
      ...axiosConfig,
      method: 'POST' as Method,
      url: reqPath,
      params: query,
      headers: { 'Content-Type': 'multipart/form' },
      data: formData,
    };

    return axios.request(reqConfig).then((res) => {
      return res.data;
    });
  }
}

export const HardcodedInsuranceCardInterpretationApi =
  new HardcodedInsuranceCardInterpretationApiResource();
