import React, { useRef, useState } from 'react';
import { DropEvent } from 'react-aria';
import { DropZone, FileTrigger } from 'react-aria-components';
import type { DropItem } from 'react-aria-components';

import { IconButton } from '@headway/helix/IconButton';
import { IconCheckCircle } from '@headway/helix/icons/CheckCircle';
import { IconFileText } from '@headway/helix/icons/FileText';
import { IconTrash } from '@headway/helix/icons/Trash';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';

interface FileUploadProps {
  children: React.ReactNode;
  onDrop: (file: File | null) => void;
  onClear: () => void;
  accept?: string[]; // array of MIME types
  validationError?: string;
}

export function FileUpload(props: FileUploadProps) {
  const [file, setFile] = useState<File | null>(null);
  const [showFileTypeWarning, setShowFileTypeWarning] =
    useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const isFileTypeAllowed = (file: File): boolean => {
    if (!props.accept?.length) {
      return true; // allow all files if no restrictions specified
    }
    return props.accept.includes(file.type);
  };

  const handleDrop = async (event: DropEvent) => {
    const files = event.items.filter(
      (item): item is DropItem => item.kind === 'file'
    );

    if (files.length > 0) {
      const droppedFile = await (files[0] as any).getFile();
      handleFileSelection(droppedFile);
    } else {
      setFile(null);
    }
  };

  const handleFileSelection = (selectedFile: File) => {
    setShowFileTypeWarning(false); // Reset warning state

    if (isFileTypeAllowed(selectedFile)) {
      setFile(selectedFile);
      props.onDrop(selectedFile);
    } else {
      setFile(null);
      setShowFileTypeWarning(true);
      props.onDrop(null);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleClear = () => {
    setFile(null);
    props.onClear();
    setShowFileTypeWarning(false);
  };

  const BYTES_PER_KB = 1024;
  const BYTES_PER_MB = 1024 * 1024;

  const formatFileSize = (bytes: number) => {
    if (bytes < BYTES_PER_KB) return `${bytes} bytes`;
    if (bytes < BYTES_PER_MB) return `${(bytes / BYTES_PER_KB).toFixed(1)} KB`;
    return `${(bytes / BYTES_PER_MB).toFixed(1)} MB`;
  };

  const getAllowedTypesText = () => {
    if (!props.accept?.length) return 'All files allowed';
    return `${props.accept
      .map((type) => type.split('/')[1].toUpperCase())
      .join(', ')}`;
  };

  const getAcceptString = () => {
    return props.accept?.join(',') || '*/*';
  };

  const errorText = props.validationError ? (
    <span
      style={{
        color: 'red',
        fontSize: '0.8rem',
        marginTop: '4px',
      }}
    >
      {props.validationError}
    </span>
  ) : null;

  const errorBorderStyle = {
    border: '1px solid red',
    borderRadius: '4px',
  };

  const normalBorderStyle = {
    border: '1px solid #DADCE0',
    borderRadius: '4px',
  };

  return (
    <>
      {!!file ? (
        <div
          className="border-system-borderGray mb-2 flex w-full items-center justify-between rounded border p-2"
          style={errorText ? errorBorderStyle : normalBorderStyle}
        >
          <div className="flex items-center space-x-4">
            <IconFileText width={36} height={36} />
            <div className="flex flex-col">
              <div className="text-sm">{file.name}</div>
              <div className="flex items-center space-x-2">
                <IconCheckCircle className="text-primary-brandGreen" />
                <span className="text-xs text-gray-500">
                  {formatFileSize(file.size)}
                </span>
              </div>
            </div>
          </div>
          <IconButton aria-label={`Delete ${file.name}`} onPress={handleClear}>
            <IconTrash />
          </IconButton>
          {errorText}
        </div>
      ) : (
        <div>
          <div style={errorText ? errorBorderStyle : normalBorderStyle}>
            <DropZone onDrop={handleDrop}>
              <div
                onClick={handleClick}
                className="cursor-pointer rounded p-4 "
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleClick();
                  }
                }}
              >
                <FileTrigger>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={(event) => {
                      const selectedFile = event.target.files?.[0];
                      if (selectedFile) {
                        handleFileSelection(selectedFile);
                      }
                    }}
                    accept={getAcceptString()}
                    className="hidden"
                    aria-label="Choose file"
                  />
                </FileTrigger>
                {props.children}
                <div></div>
                <div className="">
                  {showFileTypeWarning && (
                    <div className="">
                      <IconWarningCircle />
                      <span>
                        Unsupported file type. Accepted files:{' '}
                        {getAllowedTypesText()}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </DropZone>
          </div>
          {errorText}
        </div>
      )}
    </>
  );
}
