export const InsuranceCardFront = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 134 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g clipPath="url(#clip0_497_13146)">
      <rect
        x="0.683105"
        width="132.632"
        height="80"
        rx="2.05928"
        fill="#E8F3FF"
      />
      <mask
        id="mask0_497_13146"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="134"
        height="80"
      >
        <rect
          x="0.683105"
          width="132.632"
          height="80"
          rx="2.05928"
          fill="#FAF3F3"
        />
      </mask>
      <g mask="url(#mask0_497_13146)">
        <rect x="-6.39136" width="139.705" height="23.8737" fill="#A2D0FE" />
        <rect
          x="12.2749"
          y="10.0098"
          width="1.93058"
          height="5.66302"
          fill="#45A1FD"
        />
        <rect
          x="10.4102"
          y="13.8062"
          width="1.93058"
          height="5.66302"
          transform="rotate(-90 10.4102 13.8062)"
          fill="#45A1FD"
        />
        <rect
          x="8.97266"
          y="40.6636"
          width="53.0526"
          height="5.30526"
          rx="0.686427"
          fill="white"
        />
        <rect
          x="72.6353"
          y="40.6636"
          width="53.0526"
          height="4.64211"
          rx="0.686427"
          fill="white"
        />
        <rect
          x="72.6353"
          y="47.2939"
          width="53.0526"
          height="4.64211"
          rx="0.686427"
          fill="white"
        />
        <rect
          x="72.6353"
          y="53.9258"
          width="53.0526"
          height="4.64211"
          rx="0.686427"
          fill="white"
        />
        <rect
          x="72.6353"
          y="60.5571"
          width="53.0526"
          height="4.64211"
          rx="0.686427"
          fill="white"
        />
        <rect
          x="8.97266"
          y="50.6099"
          width="53.0526"
          height="5.30526"
          rx="0.686427"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_497_13146">
        <rect
          x="0.684082"
          width="132.632"
          height="80"
          rx="6.63158"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default InsuranceCardFront;
