import get from 'lodash/get';
import React, { useState } from 'react';

import { HardcodedInsuranceCardInterpretationApi } from '@headway/api/hardcoded-resources/HardcodedInsuranceCardInterpretationApi';
import { InterpretInsuranceCardResponse } from '@headway/api/models/InterpretInsuranceCardResponse';
import { InvalidImageReason } from '@headway/api/models/InvalidImageReason';
import { ValidateAndUploadInsuranceCardToS3Response } from '@headway/api/models/ValidateAndUploadInsuranceCardToS3Response';
import ScanningCardScreen from '@headway/benefits/OCRInsuranceCardLoadingDisplays';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { HeadlineText } from '@headway/helix/HeadlineText';
import { IconSealCheck } from '@headway/helix/icons/SealCheck';
import { IconUploadSimple } from '@headway/helix/icons/UploadSimple';
import { InsuranceCardBack } from '@headway/shared/components/images/InsuranceCardBack';
import { InsuranceCardFront } from '@headway/shared/components/images/InsuranceCardFront';
import { InsuranceLookupInputMode } from '@headway/shared/types/insuranceCapture';
import { SelectorError } from '@headway/shared/types/insuranceCapture';
import { IAuthStore, IUiStore, withStores } from '@headway/shared/types/stores';
import { trackEvent } from '@headway/shared/utils/analytics';
import {
  AnalyticsOCRQualityCheckFailureReason,
  CLIENT_SIDE_FILE_SIZE_LIMIT,
  fileTypePermitted,
  getAnalyticsOCRFailureReason,
  getAvoInsuranceFormContext,
  getBlobFromString,
  hasUploadTooLargeMessage,
  isInterpretationResponseUnreadable,
  SCANNABLE_FILE_TYPES,
} from '@headway/shared/utils/InsuranceLookupUtils';
import { formatPatientName } from '@headway/shared/utils/patient';

import { FileUpload } from '../FileUpload';
import SelectorErrorCard from './SelectorErrorCard';

const MISSING_FILE_ERROR_MESSAGE =
  'Please upload your insurance card images or enter your insurance details manually to continue.';
const NO_IMAGE_SELECTED_ERROR_MESSAGE = 'Please select an image file';
const FILE_SIZE_LIMIT_ERROR_MESSAGE = 'Image must be smaller than 10MB';
const CATCH_ALL_ERROR_MESSAGE = 'Error reading file';

interface InsuranceLookupOCREntrypointDesktopProps {
  handleInterpretation?: (
    interpretationResponse: InterpretInsuranceCardResponse
  ) => void;
  handleValidation?: (
    validationResponse: ValidateAndUploadInsuranceCardToS3Response
  ) => void;
  clearInputModeSelection: (inputModeError?: SelectorError) => void;
  setModeSelection?: (mode: InsuranceLookupInputMode) => void;
  selectorError?: SelectorError;
  includeHeading?: boolean;
  includeFooter?: boolean;
  includeEnterManuallyButton?: boolean;
  AuthStore: IAuthStore;
  UiStore: IUiStore;
  isInsideModal?: boolean;
  validatePhotosOnlyDoNotParse?: boolean;
  // Adding in an optional callback to handle skipping the insurance photos step.
  // Original use case is for the referred patient onboarding flow where we dont
  // want to block the user from continuing to onboard if they have an error with
  // their insurance card photos.
  onSkipPhotoUpload?: () => void;
}

const SkipPhotoUploadButton: React.FC<{
  onSkipPhotoUpload: () => void;
}> = ({ onSkipPhotoUpload }) => {
  return (
    <Button variant="secondary" onPress={onSkipPhotoUpload}>
      Skip for now
    </Button>
  );
};

export const InsuranceLookupOCREntrypointDesktopCore: React.FC<
  InsuranceLookupOCREntrypointDesktopProps
> = ({
  handleInterpretation,
  handleValidation = () => {},
  clearInputModeSelection,
  setModeSelection,
  selectorError,
  includeHeading,
  includeFooter,
  includeEnterManuallyButton = true,
  AuthStore,
  isInsideModal,
  validatePhotosOnlyDoNotParse = false,
  onSkipPhotoUpload,
}) => {
  const firstName = formatPatientName(AuthStore.user, {
    firstNameOnly: true,
  });
  const [frontImage, setFrontImage] = useState<string | null>(null);
  const [backImage, setBackImage] = useState<string | null>(null);

  const [frontImageError, setFrontImageError] = useState<string | undefined>(
    undefined
  );
  const [backImageError, setBackImageError] = useState<string | undefined>(
    undefined
  );

  const [
    isWaitingOnInterpretationResponse,
    setIsWaitingOnInterpretationResponse,
  ] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const onDropFrontImage = (file?: File | null) => {
    if (file) {
      setFrontImageError(undefined);

      if (!fileTypePermitted(file)) {
        setFrontImageError(NO_IMAGE_SELECTED_ERROR_MESSAGE);
        return;
      }
      if (file.size > CLIENT_SIDE_FILE_SIZE_LIMIT) {
        setFrontImageError(FILE_SIZE_LIMIT_ERROR_MESSAGE);
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        setFrontImage(e.target?.result as string);
      };
      reader.onerror = () => {
        setError(CATCH_ALL_ERROR_MESSAGE);
      };
      reader.readAsDataURL(file);
    } else {
      // TODO(OCR): not sure if it's possible to get here without a file,
      // but can set an error message as a guardrail
      setError(NO_IMAGE_SELECTED_ERROR_MESSAGE);
    }
  };

  const onDropBackImage = (file?: File | null) => {
    if (file) {
      setBackImageError(undefined);

      if (!fileTypePermitted(file)) {
        setBackImageError(NO_IMAGE_SELECTED_ERROR_MESSAGE);
        return;
      }
      if (file.size > CLIENT_SIDE_FILE_SIZE_LIMIT) {
        setBackImageError(FILE_SIZE_LIMIT_ERROR_MESSAGE);
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        setBackImage(e.target?.result as string);
      };
      reader.onerror = () => {
        setError(CATCH_ALL_ERROR_MESSAGE);
      };
      reader.readAsDataURL(file);
    } else {
      // TODO(OCR): not sure if it's possible to get here without a file,
      // but can set an error message as a guardrail
      setError(NO_IMAGE_SELECTED_ERROR_MESSAGE);
    }
  };

  const handleSubmit = async () => {
    if (!(!!frontImage && !!backImage)) {
      if (!frontImage) {
        setFrontImageError(MISSING_FILE_ERROR_MESSAGE);
      }
      if (!backImage) {
        setBackImageError(MISSING_FILE_ERROR_MESSAGE);
      }
      return;
    }

    setFrontImageError(undefined);
    setBackImageError(undefined);
    setIsWaitingOnInterpretationResponse(true);
    const query = { owner_id: AuthStore.user.id };
    let qualityCheckSucceeded: boolean = false;
    let qualityCheckFailureReason:
      | AnalyticsOCRQualityCheckFailureReason
      | undefined;
    try {
      const frontImageBlob = await getBlobFromString(frontImage!);
      const backImageBlob = await getBlobFromString(backImage!);
      if (validatePhotosOnlyDoNotParse) {
        // For some cases, we only want to validate that the image is of an insurance
        // card, without extracting or parsing any information
        const validationResponse =
          await HardcodedInsuranceCardInterpretationApi.validateAndUploadInsuranceCardToS3(
            backImageBlob,
            frontImageBlob,
            query
          );
        handleValidation(validationResponse);
        qualityCheckSucceeded = true;
        return;
      } else {
        // For the normal flow, we want to extract and parse the insurance card information
        const interpretationResponse =
          await HardcodedInsuranceCardInterpretationApi.extractInsuranceCardInfo(
            backImageBlob,
            frontImageBlob,
            query
          );
        if (isInterpretationResponseUnreadable(interpretationResponse)) {
          clearInputModeSelection(SelectorError.UNPARSEABLE_IMAGE);
          qualityCheckFailureReason = 'unparseable_model_response';
        } else {
          handleInterpretation?.(interpretationResponse);
          qualityCheckSucceeded = true;
        }
      }
    } catch (e) {
      if (hasUploadTooLargeMessage(e)) {
        clearInputModeSelection(SelectorError.UPLOAD_FILE_TOO_LARGE);
        qualityCheckFailureReason = 'file_size_too_large';
        return;
      }

      const hasInvalidImageReasons = (
        error: unknown
      ): InvalidImageReason | null => {
        const is400 = error && get(error, 'response.status') === 400;
        if (!is400) {
          return null;
        }

        // should handle this in a more strongly typed way
        const detail = get(error, 'response.data.detail');
        if (detail && typeof detail === 'string') {
          for (let reason of Object.values(InvalidImageReason)) {
            if (detail.includes(reason)) {
              return reason;
            }
          }
        }
        return null;
      };
      const reason = hasInvalidImageReasons(e);
      if (reason) {
        qualityCheckFailureReason = getAnalyticsOCRFailureReason(reason);
        if (
          reason === InvalidImageReason.BLURRY ||
          reason === InvalidImageReason.LOW_RESOLUTION
        ) {
          clearInputModeSelection(SelectorError.UNPARSEABLE_IMAGE);
          return;
        } else if (
          reason === InvalidImageReason.FILE_TOO_LARGE ||
          reason === InvalidImageReason.MAXIMUM_SIZE_EXCEEDED
        ) {
          clearInputModeSelection(SelectorError.UPLOAD_FILE_TOO_LARGE);
          qualityCheckFailureReason = 'file_size_too_large';
          return;
        }
      }

      if (validatePhotosOnlyDoNotParse) {
        clearInputModeSelection(SelectorError.PHOTO_UPLOAD_CATCHALL_ERROR);
      } else {
        clearInputModeSelection(SelectorError.CATCHALL_ERROR);
      }
    } finally {
      trackEvent({
        name: 'OCR Quality Check Completed',
        properties: {
          patientUserId: AuthStore.user.id,
          cardSide: 'both',
          ocrType: 'upload',
          qualityCheckSucceeded,
          qualityCheckFailureReason: qualityCheckSucceeded
            ? 'no_failure'
            : (qualityCheckFailureReason ?? 'unknown'),
          insuranceFormContext: getAvoInsuranceFormContext(),
        },
      });
      setIsWaitingOnInterpretationResponse(false);
    }
  };

  if (isWaitingOnInterpretationResponse) {
    return <ScanningCardScreen isMobile={false} />;
  }

  const costEstimateComponent = (
    <div className="flex max-w-[335px] flex-col items-center gap-2 self-center py-12 text-center">
      <IconSealCheck />
      <ContentText variant="body-large">
        Most of our patients find their final cost matches our estimate.
      </ContentText>
    </div>
  );

  const continueButton = (
    <Button
      onPress={handleSubmit}
      variant="primary"
      data-dd-action-name="OCR Desktop Flow - continue"
    >
      Continue
    </Button>
  );

  const enterManuallyButton = includeEnterManuallyButton ? (
    <Button
      onPress={() => {
        setModeSelection?.(InsuranceLookupInputMode.MANUAL);
        if (!isInsideModal) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      }}
      variant="secondary"
      data-dd-action-name="OCR Desktop Flow - enter manually instead"
    >
      Enter manually instead
    </Button>
  ) : null;

  return (
    <div className="flex flex-col gap-2">
      {includeHeading && (
        <>
          <HeadlineText variant="H4">
            {firstName ? `${firstName}, l` : `L`}et's estimate your cost
          </HeadlineText>
          <ContentText variant="body-large">
            We will provide you an <strong>instant price estimate</strong> if
            you are in network with us.
          </ContentText>
        </>
      )}
      {selectorError && <SelectorErrorCard selectorError={selectorError} />}
      <div className="flex flex-col gap-4">
        <h2 className="m-0">
          <ContentText variant="body-large/medium">
            Upload your insurance card images
          </ContentText>
        </h2>
        <div className="flex flex-col gap-2">
          <ContentText variant="body/medium">
            Front side of your insurance card
          </ContentText>
          <FileUpload
            onDrop={onDropFrontImage}
            onClear={() => {
              setFrontImage(null);
            }}
            accept={SCANNABLE_FILE_TYPES}
            validationError={frontImageError}
          >
            <div className="bg-system-backgroundGray flex flex-col items-center justify-center gap-4 rounded p-8 text-center">
              <InsuranceCardFront height={100} />
              <span className="flex items-center gap-2">
                <IconUploadSimple />{' '}
                <span>
                  Drag file or{' '}
                  <span className="underline">upload from computer</span>
                </span>
              </span>
            </div>
          </FileUpload>
        </div>
        <div className="flex flex-col gap-2">
          <ContentText variant="body/medium">
            Back side of your insurance card
          </ContentText>
          <FileUpload
            onDrop={onDropBackImage}
            onClear={() => {
              setBackImage(null);
            }}
            accept={SCANNABLE_FILE_TYPES}
            validationError={backImageError}
          >
            <div className="bg-system-backgroundGray flex flex-col items-center justify-center gap-4 rounded p-8 text-center">
              <InsuranceCardBack height={100} />
              <div className="flex items-center gap-2">
                <IconUploadSimple />{' '}
                <div>
                  Drag file or{' '}
                  <span className="underline">upload from computer</span>
                </div>
              </div>
            </div>
          </FileUpload>
        </div>
      </div>
      {isInsideModal ? (
        <>
          {includeFooter && costEstimateComponent}
          <div className="flex justify-end space-x-2">
            {validatePhotosOnlyDoNotParse &&
              !!onSkipPhotoUpload &&
              selectorError && (
                <SkipPhotoUploadButton onSkipPhotoUpload={onSkipPhotoUpload} />
              )}
            {enterManuallyButton}
            {continueButton}
          </div>
        </>
      ) : (
        <>
          {validatePhotosOnlyDoNotParse &&
            !!onSkipPhotoUpload &&
            selectorError && (
              <SkipPhotoUploadButton onSkipPhotoUpload={onSkipPhotoUpload} />
            )}
          {continueButton}
          {enterManuallyButton}
          {includeFooter && costEstimateComponent}
        </>
      )}
    </div>
  );
};

export const InsuranceLookupOCREntrypointDesktop = withStores(
  InsuranceLookupOCREntrypointDesktopCore
);
